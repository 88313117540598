<template>
  <!-- pdf预览 -->
  <div class='preview' v-loading="loading">
    <back />
    <backup />

    <div class="pdfs">
      <vue-pdf v-for="i in numPages" :key="i" :page="i" :src="pdfurl" />
    </div>
  </div>
</template>

<script>
import VuePdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
export default {
  components: { VuePdf },
  data () {
    return {
      loading: false,
      pdfurl: '',
      // pdfurl: '',
      numPages: 0, // pdf 总页数
    }
  },
  created () {
    this.loading = true
    this.onPdfLoaded()
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 2000);
  },
  methods: {
    onPdfLoaded () {
      // let url = 'https://xn--fcs331b8yd3xj5in.com/files/contract/802003_1054_12143_108_1054_12143_644542.pdf'
      let url = localStorage.getItem('fileUrl') || ''

      if (url == '') {
        this.$message.error('文件加载失败，请联系管理员')
      } else {
        // let url = 'https://xn--fcs331b8yd3xj5in.com/files/contract/三方合作代收代付合同-new.pdf'
        let loadingTask = VuePdf.createLoadingTask({
          url: url,
          // cMapUrl: "https://unpkg.com/browse/pdfjs-dist@2.2.228/cmaps/",
          cMapUrl: "/public/cmaps/",
          cMapPacked: true,
          CMapReaderFactory
        });

        this.pdfurl = loadingTask

        loadingTask.promise.then((pdf) => {
          this.numPages = pdf.numPages
        })
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.preview {

  .pdfs {
    width: 80%;
    height: fit-content;
    margin: 0 auto;
  }
}
</style>
